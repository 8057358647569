<template>
  <b-sidebar id="sidebar-aside" title="Sidebar" shadow right>
    <b-tabs>
      <b-tab>
        <template slot="title">
          <b-icon icon='search' v-b-tooltip.hover.lefttop title="Busqueda avanzada del sistema"></b-icon>
        </template>

        <b-list-group class="list-group-accent">
          <b-list-group-item>
            <b-input-group>
              <b-form-input v-model="filter.name" 
                            size="sm"
                            v-on:keyup.enter.native="getFilterByName()" 
                            v-b-tooltip.hover.centerbottom title="Escriba su Búsqueda">
              </b-form-input>

              <b-input-group-append>
                <b-button variant="outline-primary" 
                          size="sm" 
                          @click="getFilterByName()" 
                          v-b-tooltip.hover.centerbottom 
                          title="Filtre su Búsqueda">
                  <b-icon icon="funnel-fill"></b-icon>
                </b-button>      
              </b-input-group-append>
            </b-input-group>
          </b-list-group-item>

          <div v-if="status && !items.customers.length && !items.staff.length && !items.suppliers.length" class="p-3 text-center font-weight-bold">
            <b-alert show variant="warning">No se encontró resultados</b-alert>
          </div>
          <div v-if="!status && !items.customers.length && !items.staff.length && !items.suppliers.length" class="p-3 text-center font-weight-bold">
            <b-alert show variant="info">El filtro buscará entre los clientes, proveedores y staff</b-alert>
          </div>

          <!-- STAFF -->
          <b-list-group-item v-if="items.staff.length" class="list-group-item-accent-secondary bg-light font-weight-bold text-muted text-uppercase small p-2">
            Staff
          </b-list-group-item>
          <div v-if="items.staff.length">
            <b-list-group-item class="access-direct-select" 
                             v-for="value in items.staff" 
                             v-bind:key="value.id" 
                             @click="goStaff(value)">             
            
              
                <b-avatar :src="value.image"
                          v-if="value.image">
                </b-avatar>
                <b-avatar v-else                                                        
                          variant="dark">
                </b-avatar>     
                <b-icon icon="blank"></b-icon>
                <b>{{value.name}}</b>                   
              
            </b-list-group-item>  
          </div>   
          
          <!-- CLIENTES -->
          <b-list-group-item v-if="items.customers.length" class="list-group-item-accent-secondary bg-light font-weight-bold text-muted text-uppercase small p-2">
            Clientes
          </b-list-group-item>
          <div v-if="items.customers.length">
            <b-list-group-item class="access-direct-select" 
                             v-for="value in items.customers" 
                             v-bind:key="value.id" 
                             @click="goCustomers(value)">             
            
              
                <b-avatar :src="value.image"
                          v-if="value.image">
                </b-avatar>
                <b-avatar v-else         
                          icon="building"                                    
                          variant="dark">
                </b-avatar>     
                <b-icon icon="blank"></b-icon>
                <b>{{value.name}}</b>                   
              
            </b-list-group-item>  
          </div>   

          <!-- PROVEEDORES -->
          <b-list-group-item v-if="items.suppliers.length" class="list-group-item-accent-secondary bg-light font-weight-bold text-muted text-uppercase small p-2">
            Proveedores
          </b-list-group-item>
          <div v-if="items.suppliers.length">
            <b-list-group-item class="access-direct-select" 
                             v-for="value in items.suppliers" 
                             v-bind:key="value.id" 
                             @click="goSuppliers(value)">             
                          
                <b-avatar :src="value.image"
                          v-if="value.image">
                </b-avatar>
                <b-avatar v-else         
                          icon="building"                                    
                          variant="dark">
                </b-avatar>     
                <b-icon icon="blank"></b-icon>
                <b>{{value.name}}</b>                   
              
            </b-list-group-item>  
          </div>   

        </b-list-group>

      </b-tab>
      <!--
        <b-tab>
          <template slot="title">
            <i class='icon-people' v-b-tooltip.hover.lefttop title="Busqueda avanzada del sistema"></i>
          </template>
        </b-tab>
      -->
    </b-tabs>
  </b-sidebar>
</template>

<script>

import Error from '@/handler/error'
import serviceAPI from './services'

export default {
  name: 'DefaultAside',
  data: () => {
    return {
      filter: {
        name: ''
      },
      items: {
        customers: [],
        staff: [],
        suppliers: []
      },
      status: false,
    }
  },
  methods: {
    getFilterByName() {
      if (this.filter.name.length < 3) {
        this.$awn.alert("Ingrese al menos 3 caracteres para realizar el filtro");      
        return false
      }

      this.getCustomersByName()
      this.getStaffByName()
      this.getSuppliersByName()      
    },        

    getCustomersByName() {
      let loader = this.$loading.show()
      var result = serviceAPI.filterCustomersByName(this.filter)

      result.then((response) => {
        var data = response.data
        this.items.customers = data
        this.status = true
        loader.hide()
      })
      .catch(error => {
        this.$awn.alert(Error.showError(error));
        loader.hide()
      });
    },
    getStaffByName() {
      let loader = this.$loading.show()
      var result = serviceAPI.filterStaffByName(this.filter)

      result.then((response) => {
        var data = response.data
        this.items.staff = data
        this.status = true
        loader.hide()
      })
      .catch(error => {
        this.$awn.alert(Error.showError(error));
        loader.hide()
      });
    },
    getSuppliersByName() {
      let loader = this.$loading.show()
      var result = serviceAPI.filterSuppliersByName(this.filter)

      result.then((response) => {
        var data = response.data
        this.items.suppliers = data
        this.status = true
        loader.hide()
      })
      .catch(error => {
        this.$awn.alert(Error.showError(error));
        loader.hide()
      });
    },

    goCustomers(item) {
      this.$router.push({ name: 'CustomerViewStaff', params: {customersID: item.id} })
    },        
    goStaff(item) {
      this.$router.push({ name: 'StaffViewStaff', params: {staffID: item.id} })
    },        
    goSuppliers(item) {
      this.$router.push({ name: 'SuppliersViewStaff', params: {suppliersID: item.id} })
    }
  }
}
</script>
<style>
  .title-select {
    padding: 8px;
  }
  .access-direct-select {
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;    
    padding-right: 10px;
    border-bottom-style: solid !important;
    border-bottom-width: 1px !important;
    border-bottom-color: #eaeaea !important;
  }
</style>
