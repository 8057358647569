import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  configSession(data) {
    var url = ConfigAPI.baseURL + "configViewSession" + Session.getToken();

    var params = {
      profile_id: data.profileId,
      selector_session: data.selectorSeccion,
      selector_session_name: data.selectorSeccionName,
    }

    return instance.post(url, params);
  },
  getSessionUser() {
    var url = ConfigAPI.baseURL + "getInfoSession" + Session.getToken();

    return instance.get(url);
  },

  showStaff(id) {
    var url = ConfigAPI.baseURL + "staff/" + id + Session.getToken();
    return instance.get(url);
  },
  showCustomers(id) {
    var url = ConfigAPI.baseURL + "customers/" + id + Session.getToken();
    return instance.get(url);
  },
  showSuppliers(id) {
    var url = ConfigAPI.baseURL + "suppliers/" + id + Session.getToken();
    return instance.get(url);
  }, 
  
  filterCustomersByName(data) {    
    var params = {
      name: data.name
    }
    var url = ConfigAPI.baseURL + "customers/filter/name" + Session.getToken();;
    return instance.post(url,params);
  },  
  filterStaffByName(data) {    
    var params = {
      name: data.name
    }
    var url = ConfigAPI.baseURL + "staff/filter/name" + Session.getToken();;
    return instance.post(url,params);
  },    
  filterSuppliersByName(data) {    
    var params = {
      name: data.name
    }
    var url = ConfigAPI.baseURL + "suppliers/filter/name" + Session.getToken();;
    return instance.post(url,params);
  },    
}

export default servicesAPI;
